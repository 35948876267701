import React, { useState } from 'react'

import Controls from './Controls'
import Counter from './Counter'
import "./styles.css";

export default function App() {
  const [counter, setCounter] = useState(0)

  const handleIncrease = () => setCounter(value => value + 1)
  const handleDecrease = () => setCounter(value => Math.min(value - 1, 0))

  return (
    <div className="app">
      <h1>Counter</h1>
      <Counter value={counter} />
      <Controls increase={handleIncrease} decrease={handleDecrease} />
    </div>
  );
}
