import React from "react";

export default function Controls({ increase, decrease }) {
  return (
    <div className="controls">
      <button onClick={increase}>Increase</button>
      <button onClick={decrease}>Decrease</button>
    </div>
  );
}
